import { createContext, useContext, useEffect, useState } from "react";
import { useApi } from "./ApiProvider";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserProvider";

const ShopContext = createContext();

export default function ShopProvider({ children }) {
  const { user } = useUser();
  const [shops, setShops] = useState();
  const [currentShop, setCurrentShop] = useState();

  const api = useApi();

  useEffect(() => {
    if (user) {
      (async () => {
        const response = await api.get(`/api/shops/by/${user?._id}`);
        if (response.success) {
          setShops(response.shops);
          setCurrentShop(response.shops[0]);
        }
      })();
    }
  }, [api, user]);

  const createShop = async (shop) => {
    const response = await api.post(`/api/shops`, shop);
    if (response.success) {
      setCurrentShop(response.shop);
    }
  };

  return (
    <ShopContext.Provider
      value={{ createShop, shops, currentShop, setCurrentShop }}
    >
      {children}
    </ShopContext.Provider>
  );
}

export function useShops() {
  return useContext(ShopContext);
}
