import React from "react";
import Avatar from "../components/Avatar";
import { NavLink, useParams } from "react-router-dom";
import { useUser } from "../contexts/UserProvider";
import ShopSwitcher from "../components/ShopSwitcher";

export default function Sidebar() {
  const { shopId } = useParams();
  const { user, currentShop } = useUser();

  return (
    <>
      <div className="app-sidebar">
        <div className="app-sidebar-menu">
          <ul>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to={`/${shopId}/dashboard`}
              >
                <i class="bi bi-house"></i>
                <span>Dashboard</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to={`/shops`}
              >
                <i class="bi bi-shop"></i>
                <span>Mes boutiques</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to={`/${shopId}/products`}
              >
                <i class="bi bi-gift"></i>
                <span>Produits</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to={`/${shopId}/customers`}
              >
                <i class="bi bi-people"></i>
                <span>Clients</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to={`${shopId}/orders`}
              >
                <i class="bi bi-bag"></i>
                <span>Commandes</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to={`${shopId}/users`}
              >
                <i class="bi bi-person"></i>
                <span>Utilisateurs</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to={`${shopId}/categories`}
              >
                <i class="bi bi-list"></i>
                <span>Catégories</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="app-sidebar-footer"></div>
      </div>
    </>
  );
}
