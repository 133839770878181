import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useFlash } from "../../contexts/FlashProvider";
import { useApi } from "../../contexts/ApiProvider";

const Schema = Yup.object().shape({
  activation_token: Yup.string().required("Token requis!"),
  activation_code: Yup.string().required("Code requis!"),
});

export default function Activation() {
  const navigate = useNavigate();
  const location = useLocation();
  const flash = useFlash();
  const api = useApi();

  const parsed = queryString.parse(location.search);

  const formik = useFormik({
    initialValues: {
      activation_token: parsed?.token,
      activation_code: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      const response = await api.post(`/api/auth/activation`, values);
      if (response.success) {
        localStorage.setItem("my_admin", response.token);
        flash(`Enregistrement effectué`, `success`);
        navigate("/setup-profile");
      } else {
        flash(`${response.error.message}`, `danger`);
      }
    },
  });

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-center my-4">
        <form onSubmit={formik.handleSubmit}>
          <h2>Activation de votre compte</h2>
          <div className="form-floating mb-3">
            <input
              className="form-control outline-none"
              placeholder=""
              id="activation_code"
              name="activation_code"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.activation_code}
            />
            <label for="activation_code">Code d'activation</label>
            {formik.errors.activation_code && formik.touched.activation_code ? (
              <div>{formik.errors.activation_code}</div>
            ) : null}
          </div>
          <div className="my-3 d-grid">
            <button type="submit" className="btn btn-primary btn-lg">
              {formik.isSubmitting ? "...." : "Enregistrer"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
