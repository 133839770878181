import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./styles/app.scss";
import FlashProvider from "./contexts/FlashProvider";
import ApiProvider from "./contexts/ApiProvider";
import UserProvider from "./contexts/UserProvider";
import FlashMessage from "./components/FlashMessage";
import ModalProvider from "./contexts/ModalProvider";
import App from "./App";
import ShopProvider from "./contexts/ShopProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <FlashProvider>
      <ApiProvider>
        <UserProvider>
          <ShopProvider>
            <ModalProvider>
              <FlashMessage />
              <App />
            </ModalProvider>
          </ShopProvider>
        </UserProvider>
      </ApiProvider>
    </FlashProvider>
  </BrowserRouter>
);
