import React, { useEffect, useState } from "react";
import { useModal } from "../contexts/ModalProvider";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useUser } from "../contexts/UserProvider";

export default function ShopSwitcher() {
  const { shopId } = useParams();
  const { shops, currentShop, setCurrentShop } = useUser();
  const [filteredData, setFilteredData] = useState(shops);

  const { openModal } = useModal();
  const navigate = useNavigate();

  const handleShopChange = (id) => {
    if (shops) {
      const shop = shops.find((shop) => shop._id == id);
      setCurrentShop(shop);
      navigate(`/${id}/dahsboard`);
    }
  };

  const handleSelect = (shop) => {
    // setCurrentShop(shop);
    navigate(`/${shop._id}/dashboard`);
  };

  const handleFilter = (filterValue) => {
    let filteredArray = shops;
    if (filterValue) {
      filteredArray = shops?.filter((item) =>
        item.name.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    setFilteredData(filteredArray);
  };

  useEffect(() => {
    setFilteredData(shops);
  }, [shops]);

  useEffect(() => {
    if (shopId && shops) {
      const shop = shops.find((shop) => shop._id == shopId);
      setCurrentShop(shop);
    }
  }, [shopId, shops]);

  return (
    <>
      <div className="justify-content-between">
        <div class="dropdown">
          <button
            class="btn dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="las la-store fs-6 me-3"></span>
            <span className="fs-6">{currentShop?.name}</span>
          </button>
          <ul class="dropdown-menu">
            <div className="d-flex align-items-center gap-2 px-2 border-0">
              <span className="las la-search fs-2"></span>
              <input
                className="border-0 ml-2 outline-none p-2"
                type="search"
                placeholder="Rechercher"
                onChange={(e) => handleFilter(e.target.value)}
              />
            </div>
            <li>
              <hr class="dropdown-divider" />
            </li>
            <li>
              <h6 class="dropdown-header">Mes boutiques</h6>
            </li>
            {filteredData?.map((shop, index) => (
              <li key={index}>
                <button
                  class="btn dropdown-item d-flex align-items-center"
                  onClick={() => handleSelect(shop)}
                >
                  <span className="las la-store fs-5 me-3"></span>
                  <span> {shop.name}</span>
                  {currentShop?._id === shop._id && (
                    <span className="las la-check fs-5 ms-auto"></span>
                  )}
                </button>
              </li>
            ))}
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <button onClick={openModal} className="btn dropdown-item">
                <span className="las la-plus fs-5 me-2"></span>
                <span>CREER UNE BOUTIQUE</span>
              </button>
            </li>
          </ul>
        </div>
        {/* <div className="d-flex align-items-center">
          <Link to={`notifications`} className="btn btn-outline-light border-0">
            <span className="las la-bell fs-3"></span>
          </Link>
          <Link to={`settings`} className="btn btn-outline-light border-0">
            <span className="las la-cog fs-3"></span>
          </Link>
        </div> */}
      </div>
    </>
  );
}
