import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import AppLayout from "./layouts/AppLayout";
import LoginPage from "./pages/auth/LoginPage";
import RegisterPage from "./pages/auth/RegisterPage";
import DashboardPage from "./pages/main/DashboardPage";
import ProfilePage from "./pages/main/ProfilePage";
import ProfileEditPage from "./pages/main/ProfileEditPage";
import ItemLayout from "./pages/items/ItemLayout";
import ItemListPage from "./pages/items/ItemListPage";
import ItemEditPage from "./pages/items/ItemEditPage";
import ItemDetailsPage from "./pages/items/ItemDetailsPage";
import OrderLayout from "./pages/orders/OrderLayout";
import OrderListPage from "./pages/orders/OrderListPage";
import OrderDetailsPage from "./pages/orders/OrderDetailsPage";
import OrderEditPage from "./pages/orders/OrderEditPage";
import UserLayout from "./pages/users/UserLayout";
import UserEditPage from "./pages/users/UserEditPage";
import UserDetailsPage from "./pages/users/UserDetailsPage";
import UserListPage from "./pages/users/UserListPage";
import SettingPage from "./pages/main/SettingPage";
import NotificationPage from "./pages/main/NotificationPage";

import CategoryLayout from "./pages/categories/CategoryLayout";
import CategoryListPage from "./pages/categories/CategoryListPage";
import CategoryEditPage from "./pages/categories/CategoryEditPage";
import ThemeLayout from "./pages/themes/ThemeLayout";
import ThemeListPage from "./pages/themes/ThemeListPage";
import ThemeEditPage from "./pages/themes/ThemeEditPage";
import ThemeDetailsPage from "./pages/themes/ThemeDetailsPage";
import CustomerLayout from "./pages/customers/CustomerLayout";
import CustomerListPage from "./pages/customers/CustomerListPage";
import CustomerEditPage from "./pages/customers/CustomerEditPage";
import CustomerDetailsPage from "./pages/customers/CustomerDetailsPage";
import Activation from "./pages/auth/Activation";
import SetupProfile from "./pages/setup/SetupProfile";
import ShopLayout from "./pages/shops/ShopLayout";
import ShopListPage from "./pages/shops/ShopListPage";
import ShopEditPage from "./pages/shops/ShopEditPage";
import ShopDetailsPage from "./pages/shops/ShopDetailsPage";
import ProtectedRoute from "./components/ProtectedRoute";
import SetupShop from "./pages/setup/SetupShop";

export default function App() {
  return (
    <Routes>
      <Route path="/signin" element={<LoginPage />}></Route>
      <Route path="/register" element={<RegisterPage />}></Route>
      <Route path="/activation" element={<Activation />}></Route>
      <Route path="/setup-profile" element={<SetupProfile />}></Route>
      <Route path="/setup-shop" element={<SetupShop />}></Route>
      <Route element={<AppLayout />}>
        <Route path="/" element={<ProtectedRoute />}>
          <Route path=":shopId/dashboard" element={<DashboardPage />} />
          <Route path=":shopId/settings" element={<SettingPage />} />
          <Route path=":shopId/notifications" element={<NotificationPage />} />
          <Route path=":shopId/profile" element={<ProfilePage />}>
            <Route path="edit" element={<ProfileEditPage />}></Route>
          </Route>
          <Route path=":shopId/categories" element={<CategoryLayout />}>
            <Route path="" element={<CategoryListPage />}></Route>
            <Route path="add" element={<CategoryEditPage />}></Route>
            <Route path=":categoryId" element={<ItemDetailsPage />}></Route>
            <Route
              path=":categoryId/edit"
              element={<CategoryEditPage />}
            ></Route>
          </Route>
          <Route path=":shopId/shops" element={<ShopLayout />}>
            <Route path="" element={<ShopListPage />}></Route>
            <Route path="add" element={<ShopEditPage />}></Route>
            <Route path=":shopId" element={<ShopDetailsPage />}></Route>
            <Route
              path=":shopId/add_product"
              element={<ItemEditPage />}
            ></Route>
            <Route
              path=":shopId/edit_product/:productId"
              element={<ItemEditPage />}
            ></Route>
            <Route path=":shopId/edit" element={<ShopEditPage />}></Route>
          </Route>
          <Route path=":shopId/customers" element={<CustomerLayout />}>
            <Route path="" element={<CustomerListPage />}></Route>
            <Route path="add" element={<CustomerEditPage />}></Route>
            <Route path=":customerId" element={<CustomerDetailsPage />}></Route>
            <Route
              path=":customerId/edit"
              element={<CustomerEditPage />}
            ></Route>
          </Route>
          <Route path=":shopId/products" element={<ItemLayout />}>
            <Route path="" element={<ItemListPage />}></Route>
            <Route path="add" element={<ItemEditPage />}></Route>
            <Route path=":productId" element={<ItemDetailsPage />}></Route>
            <Route path=":productId/edit" element={<ItemEditPage />}></Route>
          </Route>
          <Route path=":shopId/orders" element={<OrderLayout />}>
            <Route path="" element={<OrderListPage />}></Route>
            <Route path=":id" element={<OrderDetailsPage />}></Route>
            <Route path=":id/edit" element={<OrderEditPage />}></Route>
          </Route>
          <Route path=":shopId/users" element={<UserLayout />}>
            <Route path="" element={<UserListPage />}></Route>
            <Route path="add" element={<UserEditPage />}></Route>
            <Route path=":id" element={<UserDetailsPage />}></Route>
            <Route path=":id/edit" element={<UserEditPage />}></Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

function RequireAuth({ children }) {
  let auth = localStorage.getItem("user");
  let location = useLocation();

  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
