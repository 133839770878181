import React, { useEffect, useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Sidebar from "./Sidebar";
import queryString from "query-string";
import { useUser } from "../contexts/UserProvider";
import ShopSwitcher from "../components/ShopSwitcher";

export default function AppLayout() {
  const { shopId } = useParams();
  const navigate = useNavigate();
  const { user, logout, shops, currentShop, setCurrentShop } = useUser();

  const handleShopChange = (id) => {
    if (shops) {
      const shop = shops.find((shop) => shop._id == id);
      setCurrentShop(shop);
      navigate(`/${id}/dahsboard`);
    }
  };

  const handleLogout = () => {
    const isOk = window.confirm(`vous voulez vous déconnectez ?`);
    if (isOk) {
      logout();
      navigate("/signin");
    }
  };

  useEffect(() => {
    console.log(shopId);
    console.log(shops);
    if (!shopId && shops) {
      setCurrentShop(shops[0]);
      navigate(`/${shops[0]._id}/dashboard`);
    }
  }, [shopId, shops]);

  return (
    <div className="app">
      <div className="app-header">
        <div className="app-header-logo">
          <h1 className="d-flex justify-content-center">
            <span>My fatihoune</span> | <ShopSwitcher />
          </h1>
          {/* {shops && shops.length > 0 && (
            <select
              onChange={(e) => handleShopChange(e.target.value)}
              value={currentShop?._id}
            >
              {shops?.map((shop, index) => (
                <option key={index} value={shop._id}>
                  {shop.name}
                </option>
              ))}
            </select>
          )} */}
        </div>
        <div className="app-header-nav">
          <Link>
            <i class="bi bi-bell"></i>
          </Link>
          <Link>
            <i class="bi bi-gear"></i>
          </Link>
          {user && (
            <>
              <div className="dropdown">
                <a
                  className="text-decoration-none dropdown-toggle d-flex align-items-center gap-3"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  data-bs-display="static"
                  aria-expanded="false"
                >
                  <img
                    className="border object-cover rounded-circle"
                    style={{
                      width: "40px",
                      height: "40px",
                      objectFit: "cover",
                    }}
                    key={user?.profilePicture}
                    src={`${
                      !user?.profilePicture
                        ? `${process.env.PUBLIC_URL}/assets/profile.png`
                        : `${process.env.REACT_APP_BACKEND_URL}/image/${user?.profilePicture}`
                    }`}
                  />
                </a>

                <ul className="dropdown-menu dropdown-menu-lg-end">
                  <li>
                    <Link className="dropdown-item" to={`users/${user._id}`}>
                      Mon Profile
                    </Link>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={handleLogout}
                    >
                      Déconnexion
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
          {/* <Link>
            {user?.profilePicture ? (
              <Avatar
                img={`${process.env.REACT_APP_BACKEND_URL}/image/${user?.profilePicture}`}
                size={35}
              />
            ) : (
              <Avatar text={user?.firstName} size={35} />
            )}
          </Link> */}
        </div>
      </div>
      <div className="app-main">
        <div className="app-main-nav">
          <Sidebar />
        </div>
        <div className="app-main-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
