import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserProvider";
import { useApi } from "../../contexts/ApiProvider";
import { useFlash } from "../../contexts/FlashProvider";

const Schema = Yup.object().shape({
  firstName: Yup.string().required("Nom requis!"),
  lastName: Yup.string().required("Prénoms requis!"),
});

export default function SetupProfile() {
  const navigate = useNavigate();
  const flash = useFlash();
  const api = useApi();
  const { setUser } = useUser();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      const response = await api.put(`/api/auth/me/update`, values);
      if (response.success) {
        flash(`Enregistrement effectué`, `success`);
        setUser(response.user);
        navigate("/setup-shop");
      } else {
        flash(`${response.error.message}`, `danger`);
      }
    },
  });

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-center my-4">
        <form onSubmit={formik.handleSubmit}>
          <h2>Enregistrez-vous</h2>
          <div className="form-floating mb-3">
            <input
              className="form-control outline-none"
              id="firstName"
              placeholder="name@example.com"
              name="firstName"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />
            <label htmlFor="firstName">Nom</label>
            {formik.errors.firstName && formik.touched.firstName ? (
              <div>{formik.errors.firstName}</div>
            ) : null}
          </div>
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="lastName"
              placeholder="Password"
              name="lastName"
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />
            <label htmlFor="lastName">Prénoms</label>
            {formik.errors.lastName && formik.touched.lastName ? (
              <div>{formik.errors.lastName}</div>
            ) : null}
          </div>
          <div className="my-3 d-grid">
            <button type="submit" className="btn btn-primary btn-lg">
              {formik.isSubmitting ? "...." : "Enregistrer"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
