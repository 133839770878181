import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserProvider";
import { useFlash } from "../../contexts/FlashProvider";

const SigninSchema = Yup.object().shape({
  password: Yup.string().min(4, "Too Short!").required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

export default function LoginPage() {
  const flash = useFlash();
  const { login } = useUser();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      isSeller: true,
      email: "",
      password: "",
    },
    validationSchema: SigninSchema,
    onSubmit: async (values) => {
      const response = await login(values);
      if (response.success) {
        localStorage.setItem("my_admin", response.token);
        navigate("/");
      } else {
        flash(`${response.error.message}`, "danger");
      }
    },
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-4 offset-sm-4 mt-5">
          <div className="text-center mb-4">
            <img src="../../images/logo.png" alt="" width="100px" />
          </div>
          <div className="card p-4 mb-4">
            <h4 className="mb-3">Connexion</h4>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email">Email adresse</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="w-100 form-control"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  placeholder="Email adresse"
                />
                {formik.errors.email && formik.touched.email ? (
                  <div className="text-danger">{formik.errors.email}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="password">Mot de passe</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  className="w-100 form-control"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  placeholder="Mot de passe"
                />
                {formik.errors.password && formik.touched.password ? (
                  <div className="text-danger">{formik.errors.password}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <button
                  disabled={formik.isSubmitting}
                  className="btn btn-primary w-100"
                  type="submit"
                >
                  {formik.isSubmitting && (
                    <span className="spinner-border spinner-border-sm me-1"></span>
                  )}
                  Connectez-vous
                </button>
              </div>
              <div className="mb-3">
                <Link
                  className="btn btn btn-light w-100 mb-2"
                  to="/forgot-pass"
                >
                  Mot de passe oubliez
                </Link>
              </div>
            </form>
          </div>
          <div className="form-separator mb-2">Nouveau sur ?</div>
          <Link className="btn btn-secondary w-100 mb-2" to="/register">
            Enregistrez-vous
          </Link>
        </div>
      </div>
    </div>
  );
}
