import React from "react";
import { Outlet, Link } from "react-router-dom";

export default function ShopLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}
