import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { useApi } from "../../contexts/ApiProvider";
import { useFlash } from "../../contexts/FlashProvider";
import { formatToDate, getFormattedNumber } from "../../helpers/Utility";
import Avatar from "../../components/Avatar";
import { useShops } from "../../contexts/ShopProvider";

export default function ItemListPage() {
  const query = useLocation().search;
  const navigate = useNavigate();
  const api = useApi();
  const { currentShop } = useShops();
  const flash = useFlash();

  const [items, setItems] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([]);

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((itemId) => itemId !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelected(items.map((item) => item._id));
    } else {
      setSelected([]);
    }
  };

  const deleteMultipleItem = async () => {
    if (window.confirm("Voulez vous supprimez ?") === true) {
      const response = await api.post(`/api/products/more`, selected);
      if (!response.error) {
        setItems(items.filter((item) => !selected.includes(item._id)));
        setFiltered(items.filter((item) => !selected.includes(item._id)));
        setSelected([]);
      }
    }
  };
  const deleteItem = async (id) => {
    if (window.confirm("Voulez vous supprimez ?") === true) {
      const response = await api.delete(`/api/products/${id}`);
      if (!response.error) {
        setItems(items.filter((item) => item._id !== id));
        setFiltered(items.filter((item) => item._id !== id));
      }
    }
  };

  useEffect(() => {
    if (currentShop) {
      (async () => {
        const response = await api.get(
          `/api/products/by/${currentShop?._id}/${query}`
        );
        if (response?.success) {
          setItems(response?.products);
          setFiltered(response?.products);
          setPages(response?.pages);
          setPage(response?.page);
        } else {
          flash(`${response.error.message}`, "danger");
        }
      })();
    }
  }, [api, query, currentShop]);

  return (
    <div>
      <header>
        <h2>
          <span>Produits</span>
        </h2>
      </header>
      <main>
        {items === undefined ? (
          <Spinner />
        ) : (
          <>
            <div className="action-bar">
              <div className="my-4 d-flex align-items-center">
                <Link to={`add`} className="btn btn-main">
                  <span className="las la-plus"></span>
                  <span>CREER</span>
                </Link>
                {selected.length > 0 && (
                  <button
                    className="btn btn-main-outline"
                    disabled={selected.length === 0}
                    onClick={deleteMultipleItem}
                  >
                    SUPPRIMER LA SELECTION
                  </button>
                )}
              </div>
              <div className="search-wrapper">
                <span className="las la-search"></span>
                <input
                  type="search"
                  placeholder="Rechercher"
                  onChange={(e) => navigate(`?q=${e.target.value}`)}
                />
              </div>
            </div>
            {items.length === 0 ? (
              <div>
                <div className="no-data">
                  <p>
                    Aucuns produits trouvés. Cliquez sur le lien ci-dessous pour
                    en ajouter
                  </p>
                  <Link to={`/products/add`} className="btn btn-main-outline">
                    CREER
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <table>
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="py-3 px-6">
                          <input type="checkbox" onChange={handleSelectAll} />
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Image
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Titre / Description
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Prix
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Date
                        </th>
                        <th scope="col" className="py-3 px-6">
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {items &&
                        items?.map((item, index) => (
                          <tr
                            key={index}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                          >
                            <td className="py-4 px-6">
                              <input
                                type="checkbox"
                                checked={selected.includes(item._id)}
                                onChange={() => handleSelect(item._id)}
                              />
                            </td>
                            <td
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {item._id && (
                                <img
                                  crossOrigin="anonymous"
                                  src={`${process.env.REACT_APP_BACKEND_URL}/image/${item?.files[0]?.name}`}
                                  className="w-[100px]"
                                />
                              )}
                            </td>
                            <td
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              <Link
                                to={`/products/${item._id}`}
                                className="item"
                              >
                                <h3>{item.name}</h3>
                                <p className="">{item.description}</p>
                              </Link>
                            </td>
                            <td
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              <h5>
                                {getFormattedNumber({ value: item.price })}
                              </h5>
                              <del>
                                {getFormattedNumber({
                                  value: item.originalPrice,
                                })}
                              </del>
                            </td>
                            <td
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {formatToDate(item.createdAt)}
                            </td>
                            <td className="py-4 px-6 text-right">
                              <div className="table-actions">
                                <Link
                                  className="btn btn-outline"
                                  to={`/products/${item._id}/edit`}
                                >
                                  <span className="icon las la-edit"></span>
                                </Link>
                                <button
                                  className="btn btn-main"
                                  onClick={() => deleteItem(item._id)}
                                >
                                  Supprimer
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <br />
                {/* Pagination */}
                <nav aria-label="Page navigation example">
                  {pages && (
                    <ul className="pagination justify-content-center">
                      {[...Array(pages)].map((p, i) => (
                        <li className="page-item">
                          <Link
                            className={`page-link ${p === page ? "" : null}`}
                            key={i}
                            to={`?page=${i + 1}`}
                          >
                            {i + 1}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </nav>
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
}
