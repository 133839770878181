import React, { useEffect, useState } from "react";
import { useApi } from "../../contexts/ApiProvider";
import { useFlash } from "../../contexts/FlashProvider";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import Avatar from "../../components/Avatar";
import Spinner from "../../components/Spinner";
import { formatToDate } from "../../helpers/Utility";

export default function ShopDetailsPage() {
  const [shop, setShop] = useState();
  const [products, setProducts] = useState();
  const [image, setImage] = useState();

  const shop_id = useParams().shopId;

  const query = useLocation().search;
  const navigate = useNavigate();
  const api = useApi();
  const flash = useFlash();

  const [filtered, setFiltered] = useState([]);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([]);

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((itemId) => itemId !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelected(products.map((item) => item._id));
    } else {
      setSelected([]);
    }
  };

  const deleteMultipleItem = async () => {
    if (window.confirm("Voulez vous supprimez ?") === true) {
      const response = await api.post(`/api/products/more`, selected);
      if (!response.error) {
        setProducts(products.filter((item) => !selected.includes(item._id)));
        setFiltered(products.filter((item) => !selected.includes(item._id)));
        setSelected([]);
      }
    }
  };
  const deleteItem = async (id) => {
    if (window.confirm("Voulez vous supprimez ?") === true) {
      const response = await api.delete(`/api/products/${id}`);
      if (!response.error) {
        setProducts(products.filter((item) => item._id !== id));
        setFiltered(products.filter((item) => item._id !== id));
      }
    }
  };

  useEffect(() => {
    if (shop_id) {
      (async () => {
        const response = await api.get(`/api/shops/${shop_id}`);
        setShop(response?.shop);
        setImage(response?.shop?.file?.name);
      })();
    }
  }, [shop_id]);

  useEffect(() => {
    if (shop_id) {
      (async () => {
        const response = await api.get(`/api/products/by/${shop_id}`);
        setProducts(response.products);
      })();
    }
  }, [shop_id]);

  return (
    <div>
      <header>
        <div className="shop-item" to={`/shops/${shop_id}`}>
          {shop?.file ? (
            <Avatar
              img={`${process.env.REACT_APP_BACKEND_URL}/image/${shop?.file.name}`}
              alt=""
              size={50}
            />
          ) : (
            <Avatar text={shop?.name} alt="" size={50} />
          )}
          <div>
            <h5>{shop?.name}</h5>
            <Link to={`/shops`}>Retour a la liste</Link>
          </div>
        </div>
      </header>
      <main>
        {products === undefined ? (
          <Spinner />
        ) : (
          <>
            <div className="action-bar">
              <div className="my-4 d-flex align-items-center gap-3">
                <Link
                  to={`/shops/${shop_id}/add_product`}
                  className="btn btn-primary"
                >
                  <span className="las la-plus"></span>
                  <span>CREER UN PRODUIT</span>
                </Link>
                {selected.length > 0 && (
                  <button
                    className="btn btn-main-outline"
                    disabled={selected.length === 0}
                    onClick={deleteMultipleItem}
                  >
                    SUPPRIMER LA SELECTION
                  </button>
                )}
              </div>
              <div className="search-wrapper">
                <span className="las la-search"></span>
                <input
                  type="search"
                  placeholder="Rechercher"
                  onChange={(e) => navigate(`?q=${e.target.value}`)}
                />
              </div>
            </div>
            <div>
              <div>
                <table>
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        <input type="checkbox" onChange={handleSelectAll} />
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Image
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Titre / Description
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Prix
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Date
                      </th>
                      <th scope="col" className="py-3 px-6">
                        <span className="sr-only">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {products &&
                      products?.map((item, index) => (
                        <tr
                          key={index}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                        >
                          <td className="py-4 px-6">
                            <input
                              type="checkbox"
                              checked={selected.includes(item._id)}
                              onChange={() => handleSelect(item._id)}
                            />
                          </td>
                          <td
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {item._id && (
                              <img
                                crossOrigin="anonymous"
                                src={`${process.env.REACT_APP_BACKEND_URL}/image/${item?.files[0]?.name}`}
                                className="w-[100px]"
                              />
                            )}
                          </td>
                          <td
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <Link to={`/items/${item._id}`} className="item">
                              <h3>{item.name}</h3>
                              <p className="">{item.description}</p>
                            </Link>
                          </td>
                          <td
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <h5>{item.discountPrice} F.CFA</h5>
                            <del>{item.price} F.CFA</del>
                          </td>
                          <td
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {formatToDate(item.createdAt)}
                          </td>
                          <td className="py-4 px-6 text-right">
                            <div className="table-actions">
                              <Link
                                className="btn btn-outline"
                                to={`/shops/${shop_id}/edit_product/${item._id}`}
                              >
                                <span className="icon las la-edit"></span>
                              </Link>
                              <button
                                className="btn btn-main"
                                onClick={() => deleteItem(item._id)}
                              >
                                Supprimer
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <br />
              {/* Pagination */}
              <nav aria-label="Page navigation example">
                {pages && (
                  <ul className="pagination justify-content-center">
                    {[...Array(pages)].map((p, i) => (
                      <li className="page-item" key={i}>
                        <Link
                          className={`page-link ${p === page ? "" : null}`}
                          key={i}
                          to={`?page=${i + 1}`}
                        >
                          {i + 1}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </nav>
            </div>
          </>
        )}
      </main>
    </div>
  );
}
