import React, { useEffect, useState } from "react";
import { useApi } from "../../contexts/ApiProvider";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useFlash } from "../../contexts/FlashProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useShops } from "../../contexts/ShopProvider";

const ProductSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

export default function ItemEditPage() {
  const [product, setProduct] = useState();
  const [images, setImages] = useState([]);
  const [categories, setCategories] = useState([]);

  const { productId } = useParams();

  const api = useApi();
  const { currentShop } = useShops();
  const flash = useFlash();
  const navigate = useNavigate();

  const handleImageChange = async (e) => {
    const newForm = new FormData();

    const files = Array.from(e.target.files);
    files.forEach((file) => {
      newForm.append("images", file);
    });

    const response = await api.post(`/api/uploads/upload-imgs`, newForm);
    if (response.success) {
      let data = images ? [...images] : [];
      setImages([...data, ...response.data]);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: product?.name || "",
      category: product?.category || "",
      description: product?.description || "",
      content: product?.content || "",
      quantity: product?.quantity || "",
      price: product?.price || "",
      originalPrice: product?.originalPrice || "",
      tags: product?.tags || "",
      isFeatured: product?.isFeatured || false,
      isArchived: product?.isArchived || false,
      isPublished: product?.isPublished || false,
      files: product?.files || [],
    },
    validationSchema: ProductSchema,
    onSubmit: async (values) => {
      values.files = images;
      if (product) {
        const response = await api.put(`/api/products/${productId}`, values);
        if (!response.error) {
          flash(`Informations du produit mise à jour`, "success");
          navigate(`/products`);
        } else {
          flash(`${response.error.message}`, "danger");
        }
      } else {
        const response = await api.post(
          `/api/products/by/${currentShop?._id}`,
          values
        );
        if (!response.error) {
          flash(`Nouveau produit crée`, "success");
          navigate(`/products`);
        } else {
          flash(`${response.error.message}`, "danger");
        }
      }
    },
  });

  const removeImage = (image) => {
    const updatedImages = [...images];
    const index = updatedImages.indexOf(image);
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  useEffect(() => {
    if (productId) {
      (async () => {
        const response = await api.get(`/api/products/${productId}`);
        setProduct(response.product);
        setImages(response.product.files);
      })();
    }
  }, [productId]);

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/categories`);
      setCategories(response.categories);
    })();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="app-form">
        <div className="app-form-header">
          <div className="container w-50 mx-auto">
            {!productId && <h2>Créer un produit</h2>}
            {productId && <h2>Modifier un produit</h2>}
          </div>
        </div>
        <div className="app-form-content">
          <div className="container w-50 mx-auto">
            <br />
            <div>
              <label className="pb-2">
                Nom <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={formik.values.name}
                onChange={formik.handleChange}
                placeholder="Entrer le titre..."
              />
            </div>
            <br />
            <div>
              <label className="pb-2">
                Catégorie <span className="text-danger">*</span>
              </label>
              <select
                className="form-control"
                name="category"
                value={formik.values.category}
                onChange={formik.handleChange}
              >
                <option value="Choose a category">Choisir une catégorie</option>
                {categories &&
                  categories.map((i) => (
                    <option value={i._id} key={i.name}>
                      {i.name}
                    </option>
                  ))}
              </select>
            </div>
            <br />
            <div>
              <label className="pb-2">
                Description <span className="text-danger">*</span>
              </label>
              <textarea
                cols="30"
                required
                rows="8"
                type="text"
                name="description"
                className="form-control"
                value={formik.values.description}
                onChange={formik.handleChange}
                placeholder="Entrer la description..."
              ></textarea>
            </div>
            <br />
            <div>
              <label className="pb-2">
                Contenue <span className="text-danger">*</span>
              </label>
              <ReactQuill
                style={{ height: "300px", marginBottom: 50 }}
                name="content"
                cols="30"
                onChange={(e) => formik.setFieldValue("content", e)}
                value={formik.values.content || ""}
              />
              {formik.touched.content && formik.errors.content ? (
                <span className="text-red-500">{formik.errors.content}</span>
              ) : null}
            </div>
            <br />
            <div>
              <label className="pb-2">Quantité</label>
              <input
                type="number"
                name="quantity"
                value={formik.values.quantity}
                className="form-control"
                onChange={formik.handleChange}
                placeholder="Entrer la quantité..."
              />
            </div>
            <br />
            <div>
              <label className="pb-2">
                Price <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                name="price"
                value={formik.values.price}
                className="form-control"
                onChange={formik.handleChange}
                placeholder="Enter your product price with discount..."
              />
            </div>
            <br />
            <div>
              <label className="pb-2">Original Price</label>
              <input
                type="number"
                name="originalPrice"
                value={formik.values.originalPrice}
                className="form-control"
                onChange={formik.handleChange}
                placeholder="Enter your product originalPrice..."
              />
            </div>
            <br />
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="isFeatured"
                checked={formik.values.isFeatured}
                value={formik.values.isFeatured}
                onChange={formik.handleChange}
                id="isFeatured"
              />
              <label class="form-check-label" for="isFeatured">
                En entête
              </label>
            </div>
            <br />
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="isPublished"
                checked={formik.values.isPublished}
                value={formik.values.isPublished}
                onChange={formik.handleChange}
                id="isPublished"
              />
              <label class="form-check-label" for="isPublished">
                Publier ?
              </label>
            </div>
            <br />
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="isArchived"
                checked={formik.values.isArchived}
                value={formik.values.isArchived}
                onChange={formik.handleChange}
                id="isArchived"
              />
              <label class="form-check-label" for="isArchived">
                Archiver ?
              </label>
            </div>
            <br />
            <div>
              <label className="pb-2">
                Upload Images <span className="text-danger">*</span>
              </label>
              <input
                type="file"
                name="images"
                id="images"
                className="hidden"
                style={{ display: "none" }}
                multiple
                onChange={handleImageChange}
              />
              <div className="w-full flex items-center flex-wrap">
                <label htmlFor="images">
                  <span className="las la-plus-circle fs-1 mt-3"></span>
                </label>
                {images &&
                  images.map((image, index) => (
                    <>
                      <img
                        src={`${process.env.REACT_APP_BACKEND_URL}/image/${image.name}`}
                        key={index}
                        alt=""
                        className="h-[120px] w-[120px] object-cover m-2"
                        style={{ width: "120px", height: "120px" }}
                      />
                      <button
                        className="btn"
                        onClick={() => removeImage(image)}
                      >
                        <i class="bi bi-x-lg"></i>
                      </button>
                    </>
                  ))}
              </div>
              <br />
            </div>
          </div>
        </div>
        <div className="app-form-footer">
          <div className="container w-50 mx-auto">
            <div className="d-flex align-items-center justify-content-between">
              <Link
                to={`/shops/${currentShop?._id}`}
                className="btn btn-main-outline"
              >
                ANNULER
              </Link>
              <button type="submit" className="btn btn-main">
                ENREGISTRER
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
