import React from "react";
import { Outlet, Link } from "react-router-dom";

export default function CategoryLayout() {
  return (
    <>
      <header>
        <div>
          <h2>
            <span>Catégories</span>
          </h2>
          <p>Gérer les catégories</p>
        </div>

        <div className="options-wrapper"></div>
      </header>
      <main>
        <Outlet />
      </main>
    </>
  );
}
