import React from "react";

export default function Avatar({ img, text, size }) {
  return (
    <>
      {img ? (
        <ImageAvatar image={img} alt="" size={size} />
      ) : (
        <TextAvatar text={text} size={size} />
      )}
    </>
  );
}

const TextAvatar = ({ text, size }) => {
  const getRandomColor = () => {
    const colors = [
      "#2196F3",
      "#4CAF50",
      "#FF9800",
      "#E91E63",
      "#9C27B0",
      "#F44336",
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const avatarStyle = {
    width: size || 70,
    height: size || 50,
    borderRadius: "50%",
    backgroundColor: getRandomColor(),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: size ? size * 0.5 : 25,
    fontWeight: "bold",
    color: "white",
    textTransform: "uppercase",
  };

  return <div style={avatarStyle}>{text?.charAt(0)}</div>;
};

const ImageAvatar = ({ image, alt, size }) => {
  return (
    <img
      src={image}
      alt={alt || "Avatar"}
      style={{
        width: size || 50,
        height: size || 50,
        borderRadius: "50%",
      }}
    />
  );
};
