import React, { useEffect, useState } from "react";
import { useApi } from "../../contexts/ApiProvider";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useFlash } from "../../contexts/FlashProvider";
import { useUser } from "../../contexts/UserProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiOutlinePlusCircle } from "react-icons/ai";

const ProductSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

export default function ShopEditPage() {
  const [shop, setShop] = useState();
  const [image, setImage] = useState();

  const shopId = useParams().shopId;
  const api = useApi();
  const flash = useFlash();
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: shop?.name || "",
      description: shop?.description || "",
      file: shop?.file || undefined,
    },
    validationSchema: ProductSchema,
    onSubmit: async (values) => {
      if (shopId) {
        const response = await api.put(`/api/shops/${shopId}`, values);
        if (response.success) {
          flash(`Modification effectué`, `success`);
          navigate(`/shops/${response.shop._id}`);
        } else {
          flash(`${response.error.message}`, `danger`);
        }
      } else {
        const response = await api.post(`/api/shops`, values);
        if (response.success) {
          flash(`Enregistrement effectué`, `success`);
          navigate(`/shops`);
        } else {
          flash(`${response.error.message}`, `danger`);
        }
      }
    },
  });

  useEffect(() => {
    if (shopId) {
      (async () => {
        const response = await api.get(`/api/shops/${shopId}`);
        setShop(response.shop);
        setImage(response.shop.file.name);
      })();
    }
  }, [shopId]);

  const handleFileChange = async (event) => {
    let imageData = new FormData();
    imageData.append("imageMsg", event.target.files[0]);
    const response = await api.post(`/api/uploads/upload-img`, imageData);
    if (response.success) {
      formik.setFieldValue("file", response.data._id);
      setImage(response.data.name);
    } else {
      flash(`${response.message}`, `danger`);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="app-form">
        <div className="app-form-header">
          <div className="container w-50 mx-auto">
            {!shopId && <h2>Créer une boutique</h2>}
            {shopId && <h2>Modifier une boutiques</h2>}
          </div>
        </div>
        <div className="app-form-content">
          <div className="container w-50 mx-auto">
            <br />
            <div>
              <label className="pb-2">
                Nom de la boutique <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={formik.values.name}
                onChange={formik.handleChange}
                placeholder="Entrer le nom de votre boutique..."
              />
            </div>
            <br />
            <div>
              <label className="pb-2">
                Description <span className="text-danger">*</span>
              </label>
              <textarea
                cols="30"
                required
                rows="8"
                type="text"
                name="description"
                className="form-control"
                value={formik.values.description}
                onChange={formik.handleChange}
                placeholder="Decriver votre boutique..."
              ></textarea>
            </div>
            <br />
            <div>
              <label className="pb-2">
                Charger votre logo <span className="text-danger">*</span>
              </label>
              <input
                type="file"
                name="images"
                id="images"
                className="hidden"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <div className="w-full flex items-center flex-wrap">
                <label htmlFor="images">
                  <AiOutlinePlusCircle
                    size={30}
                    className="mt-3"
                    color="#555"
                  />
                </label>
                {image && (
                  <img
                    src={
                      typeof image === "object"
                        ? URL.createObjectURL(image)
                        : `${process.env.REACT_APP_BACKEND_URL}/image/${image}`
                    }
                    alt=""
                    className="h-[120px] w-[120px] object-cover m-2"
                    style={{ width: "120px", height: "120px" }}
                  />
                )}
              </div>
              <br />
            </div>
          </div>
        </div>
        <div className="app-form-footer">
          <div className="container w-50 mx-auto">
            <div className="d-flex align-items-center justify-content-between">
              <Link to={`/shops`} className="btn btn-main-outline">
                ANNULER
              </Link>
              <button type="submit" className="btn btn-main">
                ENREGISTRER
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
