import React, { useEffect, useState } from "react";
import { useApi } from "../../contexts/ApiProvider";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useFlash } from "../../contexts/FlashProvider";
import { useUser } from "../../contexts/UserProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useShops } from "../../contexts/ShopProvider";

const CategorySchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

export default function CategoryEditPage() {
  const [category, setCategory] = useState();
  const [image, setImage] = useState();

  const categoryId = useParams().categoryId;

  const api = useApi();
  const flash = useFlash();
  const navigate = useNavigate();
  const { user } = useUser();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: category?.name || "",
      description: category?.description || "",
      slug: category?.slug || "",
      icon: category?.icon || "",
      parent: category?.parent || "",
      file: category?.file || undefined,
    },
    validationSchema: CategorySchema,
    onSubmit: async (values) => {
      if (categoryId) {
        const response = await api.put(`/api/categories/${categoryId}`, values);
        if (response.success) {
          flash(`Modification effectué`, `success`);
          navigate(`/categories`);
        } else {
          flash(`${response.error.message}`, `danger`);
        }
      } else {
        const response = await api.post(`/api/categories`, values);
        if (response.success) {
          flash(`Enregistrement effectué`, `success`);
          navigate(`/categories`);
        } else {
          flash(`${response.error.message}`, `danger`);
        }
      }
    },
  });

  useEffect(() => {
    if (categoryId) {
      (async () => {
        const response = await api.get(`/api/categories/${categoryId}`);
        setCategory(response?.category);
        setImage(response?.category?.file?.name);
      })();
    }
  }, [categoryId]);

  const handleFileChange = async (event) => {
    let imageData = new FormData();
    imageData.append("imageMsg", event.target.files[0]);
    const response = await api.post(`/api/uploads/upload-img`, imageData);
    if (response.success) {
      formik.setFieldValue("file", response.data._id);
      setImage(response.data.name);
    } else {
      flash(`${response.message}`, `danger`);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="app-form">
        <div className="app-form-header">
          <div className="container w-50 mx-auto">
            {!categoryId && <h2>Créer une catégorie</h2>}
            {categoryId && <h2>Modifier une catégorie</h2>}
          </div>
        </div>
        <div className="app-form-content">
          <div className="container w-50 mx-auto">
            <br />
            <div>
              <label className="pb-2">
                Nom de la catégorie <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={formik.values.name}
                onChange={formik.handleChange}
                placeholder="Entrer le nom de votre boutique..."
              />
            </div>
            <br />
            <div>
              <label className="pb-2">Description</label>
              <textarea
                cols="30"
                rows="8"
                type="text"
                name="description"
                className="form-control"
                value={formik.values.description}
                onChange={formik.handleChange}
                placeholder="Decrivez la categorie..."
              ></textarea>
            </div>
            <br />
            <div>
              <label className="pb-2">Charger une image</label>
              <input
                type="file"
                name="images"
                id="images"
                className="hidden"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <div className="w-full flex items-center flex-wrap">
                <label htmlFor="images">
                  <AiOutlinePlusCircle
                    size={30}
                    className="mt-3"
                    color="#555"
                  />
                </label>
                {image && (
                  <img
                    src={`${
                      !image
                        ? `${process.env.PUBLIC_URL}/assets/no-image.png`
                        : `${process.env.REACT_APP_BACKEND_URL}/image/${image}`
                    }`}
                    alt=""
                    className="h-[120px] w-[120px] object-cover m-2"
                    style={{ width: "120px", height: "120px" }}
                  />
                )}
              </div>
              <br />
            </div>
          </div>
        </div>
        <div className="app-form-footer">
          <div className="container w-50 mx-auto">
            <div className="d-flex align-items-center justify-content-between">
              <Link to={`/categories`} className="btn btn-main-outline">
                ANNULER
              </Link>
              <button type="submit" className="btn btn-main">
                ENREGISTRER
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
